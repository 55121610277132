import { Fragment } from "react";
import "./IntroContainer.scss";

const IntroContainer = (props) => {
  return (
    <Fragment>
      <div className="introContainer">
        {
          (props.title) ? <h1>{props.title}</h1> : null
        }
        {
          (props.subtitle) ? <h2>props.subtitle</h2> : null
        }
        {props.children}
      </div>
    </Fragment>
  );
};

export default IntroContainer;
