import { Fragment, Component } from "react";
import "./About.css";
import ContentHolder from "./ContentHolder/ContentHolder";
import StoryContainer from "./StoryContainer/StoryContainer";
import Team from "./Team/Team";
import StayConnectedPanel from "../../UI/StayConnectedPanel/StayConnectedPanel";
import IntroContainer from "../../UI/Intro/IntroContainer";
import DocumentMeta from "react-document-meta";

class About extends Component {
  state = {
    isDrawerActive: false,
  };

  componentDidMount() {
    document.title = "Anvarat - About Us";
  }

  toggleDrawer = () => {
    var isActive = this.state.isDrawerActive;
    isActive = !isActive;
    this.setState({ isDrawerActive: isActive });
  };

  render() {
    return (
      <Fragment>
      <DocumentMeta description="About Anvarat, our story and our team. How we started, ehat is our vision and how we plan to achieve it" />
        <div className="about">
          <IntroContainer
            title="About Us"
            subTitle="Anvarat means continuously stiving to find a way. Just like river which cannot be stopped by mountains, unless it meets the ocean."
          />
          <ContentHolder>
            <div className="container">
              <StoryContainer />
              <Team />
            </div>
            <StayConnectedPanel title="Stay Connected" description='Connect and know about what are we upto and what ate the latest news'/>
          </ContentHolder>
        </div>
      </Fragment>
    );
  }
}

export default About;
