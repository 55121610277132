import { useEffect } from 'react';
import './privacyPolicy.scss';
import DocumentMeta from 'react-document-meta';

const PrivacyPolicy = () => {

    useEffect(() => {
        // Update the document title using the browser API
        document.title = "Anvarat - Privacy Policy";
      });

    return (
         <div className="container-fluid privacy-policy">
         <DocumentMeta description="Privacy policy of Anvarat. Understand how we collect and use user data." />
        
            <div className="container">
                <h1>Privacy Policy</h1>

            
                <p>Last updated: June 01, 2021</p>

                <p>We are Anvarat Studio. Our games are available via Google Play Store, and Steam.
                </p>
                <p>By using our Services, you agree to be bound by this Privacy Policy and consent to our processing of information as specified therein.
                </p>

                <h2>1. INFORMATION COLLECTED FROM YOU</h2>

                <p>At Rebel Twins we do not collect your personal data, however our ads suppliers use them to personalise ads for you:</p>

                <p>You’ll find list of our partners’ below:</p>

                <ul>
                <li>Unity Ads</li> 

                <li>AdMob</li>

                <li>Facebook Ads</li>
                </ul>

                <p>If you log into the App using a third party site or platform such as Apple Game Center and Google Sign-In, we access information about you from that site or platform, such as your screen name, profile information and friend lists, in accordance with the authorization procedures determined by such third party site or platform provided that you have given the third party site in question such consent.
                </p>

                <h3>Learn more:</h3>

                <p>Google Play Games: <a href="https://policies.google.com/privacy" target="new">https://policies.google.com/privacy</a></p>

                <p>If you log into the App using a third party site or platform, you represent and warrant that (i) your access and use of such features in connection with the App will comply with the applicable terms and policies of such site or platform; and (ii) that you are over the minimum age limit that is prescribed for such third party site or platform by the legislation in the individual jurisdictions.
</p>
                <p>We use third-party analytics provider for the purposes of delivering our services, conducting analysis, and research. Those partners also usually collect data via their own SDKs. You will find a list of our partners implementing analytical SDKs through our applications hereafter:
</p>
                <ul>

                <li>Unity Analytics
                </li>

                <li>Facebook Analytics
                </li>
                </ul>

                <h2>2. YOUR RIGHTS</h2>

                <p>Our Privacy Policy reflects the General Data Protection Regulation (GDPR) standards.</p>

                <p>You can always opt-out from your device settings or you can access and update your information by contacting our partners’. Please check their Privacy Policies for more informations.
                </p>

                <p>The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.
                </p>

                <h2>3. CHILDREN’S PRIVACY</h2>

                <p>These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. Users declare themselves to be adult by accepting Privacy Policy. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.
                </p>

                <h2>
                4. CHANGES
                </h2>

                <p>We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.
                </p>

                <h2>5. CONTACT US</h2>

                <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us.</p>
            </div>
        </div>
    );
}

export default PrivacyPolicy;