import { Fragment } from "react";
import Title from "../../../UI/Title/Title";
import classes from "./BetaTestingContainer.module.css";
import betaTestingPlaceholderImage from "../../../assets/betaTestingPlaceholder.png";
import InputBox from "../../../UI/InputBox/InputBox";
import closedBeta from "../../../assets/closed_beta.png";

const BetaTestingContainer = (props) => {
  return (
    <Fragment>
      <div className={classes.betaTestingContainer}>
        <div className={classes.emailHolder}>
          <div className={classes.detailsContainer}>
            <span className={classes.closedBetaTitle}>closed beta</span>
            <span className={classes.closedBetaDescription}>
              Become a beta tester, test our games before anyone else and share
              your feedback
            </span>
            <div className={classes.submitButton}>
              <InputBox>Join</InputBox>
            </div>
          </div>
          <img
            className={classes.displayImage}
            src={closedBeta}
            alt="Beta testing placeholder"
          ></img>

        <Title className={classes.title}>Closed Beta</Title>
        </div>
      </div>
    </Fragment>
  );
};

export default BetaTestingContainer;
