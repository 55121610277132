import { Fragment } from "react";
import "./CardButton.css";

const CardButton = (props) => {
  return (
    <Fragment>
      <button className = 'cardButton' onClick={props.onClick}>{props.children}</button>
    </Fragment>
  );
};

export default CardButton;
