import { Fragment } from "react";
import "./ImageTextPanel.css";
import classnames from "classnames";
import CardButton from "../Buttons/CardButton/CardButton";
import underlineImage from "../../assets/title_underline.png";

const ImageTextPanel = (props) => {
  var allClasses = classnames("imageTextPanel", "right", props.className);
  if (props.left) {
    allClasses = classnames("imageTextPanel", "left", props.className);
  }

  var buttonClasses = classnames("hide");
  if (props.showButton) {
    buttonClasses = classnames("playStoreButtonContainer", 'padding0');
  }

  var titleClasses = classnames("hide");
  if (props.title) {
    titleClasses = classnames("titleContainer", "padding0");
  }

  return (
    <Fragment>
      <div className={allClasses}>
        <div className="textDiv">
          <span className={titleClasses}>
            <h2 className="title">{props.title}</h2>
            <img className="underline" src={underlineImage} alt="" />
          </span>

          <h3>{props.children}</h3>
          <span className={buttonClasses}>
            <div className="playStoreButtonHolder">
              <CardButton onClick={props.onClickButton}>Play Store</CardButton>
            </div>
          </span>
        </div>

        <div className="imgDiv">
          <img src={props.imgSrc} alt={props.alt} />
        </div>
      </div>
    </Fragment>
  );
};

export default ImageTextPanel;
