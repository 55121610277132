import { Fragment } from "react";
import classes from "./GamesContainer.module.css";
import Title from "../../../UI/Title/Title";
import GameCards from "./GameCards/GameCards";
import BetaTestingContainer from "../BetaTestingContainer/BetaTestingContainer";


const GamesContainer = (props) => {

  return (
    <Fragment>
      <div className={classes.gamesContainer}>
        <Title>Our Games</Title>
        <GameCards></GameCards>
        <BetaTestingContainer/>
      </div>
    </Fragment>
  );
};

export default GamesContainer;
