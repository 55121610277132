import { Fragment, Component } from "react";
import ImageTextPanel from "../../UI/ImageTextPanel/ImageTextPanel";
import IntroContainer from "../../UI/Intro/IntroContainer";
import Title from "../../UI/Title/Title";
import ContentHolder from "../About/ContentHolder/ContentHolder";
import vajraGameplayImage from "../../assets/vajraGameplay.png";
import vajraImage from "../../assets/vajra.png";
import veghImage from "../../assets/Vegh.png";
import leftArrowImage from "../../assets/left_arrow_red.png";
import rightArrowImage from "../../assets/right_arrow_red.png";
import titleImage from '../../assets/vajra_text_white.png';
import DocumentMeta from 'react-document-meta';

import "./Vajra.css";

class Vajra extends Component{

  componentDidMount() {
    document.title = "Vajra Game";
  }

  render() {
    return (
      <Fragment>
      <DocumentMeta description="Vajra game - Vajra is a story of struggle and vengeance. Environment inspired by the villages of Uttarakhand. It is a 3D action adventure game" />
        <div className="vajra">
          <IntroContainer>
            <div class="container introTitle">
              <img className='titleImage' src = {titleImage} alt='titleImg'/>
              <h3 className='titleText'>Vajra is a story of struggle and vengeance. Environment inspired by the villages of Uttarakhand.</h3>
            </div>
          </IntroContainer>
  
          <ContentHolder>
            <Title leftArrow={leftArrowImage} rightArrow={rightArrowImage}>
              Story & Concept
            </Title>
            <div className="container">
              <div className="infoText">
                <p>
                  A great war is headed towards the kingdom, hence king orders to
                  garrison at the rajdhani (capital city). All the resources are
                  being collected and every man with skill related to warfare is
                  being called to the rajdhani.
                </p>
                <p>
                  Vegh’s father who is a renowned weapon smith is also taken by the
                  king’s men. Overtime Vegh’s mother falls sick and to get
                  medicines Vegh visits rajdhani, but had to return empty handed,
                  leading to his mother’s death.
                </p>
                <p>
                  Vegh put her death on the king’s head since he believes that the
                  king who does not care about his subject is not fit to rule.
                  Hence he sets forth on a journey to overthrow the king. For this he
                  makes alliances with the king’s enemies.
                </p>
                <p>
                  Will these alliances bring victory or not? will he stand a chance against the king?
                   its worth watching if his quest will be a success or failure.
                </p>
              </div>
              <ImageTextPanel imgSrc={vajraImage} title="VAJRA THE WEAPON">
                <p>
                  Vajra is a weapon which symbolizes the properties of a diamond
                  (indestructibility) and a thunderbolt (irresistible force). It
                  is also considered as the king of all weapons.
                </p>
                <p>
                  In the game Vajra was forged by Vegh’s father, who is a renowned
                  weapon smith. Vajra will have the ability to absorb enemy’s
                  skills and grant them to the wielder, hence overtime Vegh will
                  gain skills by facing different kind of foes. Vajra will be able
                  to change form and become the weapon which wielder desires to
                  use. but since Vegh is most familiar with non-lethal staff, that
                  will be the first form of Vajra.
                </p>
              </ImageTextPanel>
              <ImageTextPanel left={true} imgSrc={veghImage} title="VEGH">
                <p>Vegh is the central character of the story. He is a notorious
                teenager, who keeps creating troubles for the village people, but
                kind and helpful at heart.
                </p>
                <p>
                  Everything changes since the day his mother dies, and his father
                  was not present since he was taken to the rajdhani by King’s
                  men.
                </p>
                <p>
                  Now his heart is full of sadness and vengeance, and all he want
                  is to free his father and avenge his mother’s death
                </p>
              </ImageTextPanel>
              <ImageTextPanel imgSrc={vajraGameplayImage} title="ENVIRONMENT">
                <p>
                  Story is based on Uttarakhand’s Mountain region. The village
                  environment, people, their attire and language are
                  inspired by the villages of Uttarakhand.
                </p>
                <p>
                  The story is of ancient time when gods and demons used to roam
                  earth and hence the game will exhibit glimpses of characters
                  from Hindu culture.
                </p>
              </ImageTextPanel>
            </div>
          </ContentHolder>
        </div>
      </Fragment>
    );
  }
};

export default Vajra;
