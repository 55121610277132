import { Fragment } from "react";
import classes from "./GameCards.module.css";
import GameCard from "./GameCard/GameCard";
import placeholderImage from "../../../../assets/placeholder.png";
import miniTLP from "../../../../assets/wallpaper_small.jpg"
import miniVajra from "../../../../assets/mini_vajra.png"
import miniMobile from "../../../../assets/mini_mob.png"

const GameCards = (props) => {
  var images = [
    miniTLP,
    miniVajra,
    miniMobile,
  ];
  var description = [
    <div>
      <p>
        Loss, grief and anger makes an army commander set forth on a journey to
        find the purpose of his life.
      </p>
      <p>
        As he travels across different realms to find enlightenment, he finds it
        unavoidable to draw his swords and forge his path forward.
      </p>
      <p>
        In every realm he will face different foes, and puzzles which he must
        face to proceed. Join him in his journey and find out what he gets in
        the end
      </p>
    </div>,

    <div>
      <p>
        when the king started preparing for a great war his decisions changed
        the life of a notorious boy. His father was taken away by the king and
        his mother died due to lack of resources.
      </p>
      <p>
        Now this boy Vegh wields a Vajra which was forged by his father, and
        sets forth to avenge his mother and free his father.
      </p>
      <p>
        His journey will be a twist of turns when he finds allies to overthrow
        the king.
      </p>
    </div>,

    <div>
      <p>
        Crazy pets - the ultimate chase for candy: This is a hyper-casual android
        game we created for players who want to sit back and enjoy a infinite
        chase. Unlock new pets and collect pickups to add more excitement.
      </p>
      <p>
        Double jump - The Endless Jump Saga: This is an endless survival,
        hyper-casual game launched for android. You play as a cube trying to
        survive through a trap where different launchers are shooting deadly
        missiles. Lets see how long will you survive.
      </p>
    </div>
  ];

  return (
    <Fragment>
      <div className={classes.gameCards}>
        <GameCard
          title="The Longest Path"
          description={description[0]}
          src={images[0]}
          alt="The Longest Path Image"
          linkURL="games/tlp"
        ></GameCard>
        <GameCard
          title="Vajra"
          description={description[1]}
          src={images[1]}
          alt="Vajra Image"
          linkURL="/games/vajra"
        ></GameCard>
        <GameCard
          title="Crazy pets & Double jump"
          description={description[2]}
          src={images[2]}
          alt="Crazy pets & Double jump Image"
          linkURL="/games/CPNDJ"
        ></GameCard>
      </div>
    </Fragment>
  );
};

export default GameCards;
