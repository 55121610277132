import { Fragment, Component } from "react";
import IntroContainer from "../../UI/Intro/IntroContainer";
import GamesContainer from "./GamesContainer/GamesContainer";
import "./Home.css";
import DocumentMeta from 'react-document-meta';

class Home extends Component {

  componentDidMount() {
    document.title = "Anvarat Studio - Home";
  }

  render() {
    var title = (
      <Fragment>
        We are Anvarat <br />
        Studio India
      </Fragment>
    );
    return (
      <Fragment> 
      <DocumentMeta description="Anvarat Studio - Indian independent game studio working on amazing games" />  
        <div className="home">
          <IntroContainer
            title={title}
            subTitle="We enjoy making games as much as you enjoy playing them!"
          />
          <GamesContainer />
        </div>
      </Fragment>
    );
  }
}

export default Home;
