import { Fragment, useState } from "react";
import { Nav, Navbar } from "react-bootstrap";

//social icons
import FB from '../assets/social/fb.png';
import IN from '../assets/social/in.png';
import DC from '../assets/social/dc.png';
import YT from '../assets/social/yt.png';
import ContactUs from "../UI/ContactUs/contactUs";

const Footer = (props) => {

  const [showContactUs, setShowContactUs] = useState(false);

  const openContactUs = () => {
    console.log("clicked contact us");
    setShowContactUs(true);
  }

  const closeContactUs = () => {
    setShowContactUs(false);
  }

  return (
    <Fragment>
      <div className="footer container-fluid">
      <div className="container">

      <Navbar  expand="true" variant="dark">
    <Nav className="mr-auto">
      <Nav.Link href="#" onClick={openContactUs} >CONTACT US</Nav.Link>
      <Nav.Link href="/privacy-policy">PRIVACY POLICY</Nav.Link>
      <div className="social-links">
      <Nav.Link href="https://www.facebook.com/AnvaratStudio" target="new" className="social"><img src={FB} /></Nav.Link>
      
      <Nav.Link href="https://www.instagram.com/anvaratstudio/" target="new" className="social"><img src={IN} /></Nav.Link>
      
      <Nav.Link href="https://discord.gg/GAguecMUaT" target="new" className="social"><img src={DC} /></Nav.Link>
      
      <Nav.Link href="https://www.youtube.com/channel/UChDB55F7PJ7VEjBMsrYeZeg" target="new" className="social"><img src={YT} /></Nav.Link>
      </div>
      </Nav>
    <Navbar.Text>
      Anvarat Studio. All Rights Reserved.
    </Navbar.Text>
</Navbar>

<ContactUs showContactUs={showContactUs} handleClose={closeContactUs} />

      </div>
      </div>
    </Fragment>
  );
};

export default Footer;
