import { Fragment } from "react";
import classes from "./Team.module.css";
import Title from "../.././../UI/Title/Title";
import TeamMember from "./TeamMember/TeamMember";

import astuImage from "../../../assets/Astu.png";
import jaiImage from "../../../assets/Jai.png";
import mrinalImage from "../../../assets/Mrinal.png";
import pstImage from "../../../assets/pst.png";

const Team = (props) => {
  var MemberDescription = [
    
    (<Fragment>
      <p>
        Jai Completed his graduation as a computer science engineer and has been associated with
        gaming industries from the begining of his career in 2015.
      </p>
      <p>
        He is passionate about playing games and making games. Has been known by many in the gaming community
        and has delivered some well known game titles.
      </p>
      <p>
      His purpose is to some day make games based on Indian mythology as our mythology is quite rich and unexplored when it comes to gaming.
      According to him games are powerful tool to educate younger generation about Hindu Mythology.
      </p>
    </Fragment>),
    (<Fragment>
      <p>
        Mrinal did B.tech in IT and post graduated in Design from IIT Delhi. Has been associated with many big firms
        which helped him evolve as a designer.
      </p>
      <p>
        He is very passionate about art, 3D modeling, sculpting, animation and vfx. He likes to play games, watch anime and create art in his
        free time.
      </p>
      <p>
        It has been his dream since childhood to someday make games and express himself, tell his stories in which the players
        can experience the story immersively. Hoping to make world laugh and cry through games.
      </p>
    </Fragment>),
    (<Fragment>
      <p>
      Prashasti holds degree of Btech in computer science. She has good experience of quality management, she has worked as Senior Software
       Engineer in test and is proficient in managing quality of complex products.</p>
      <p>She have interest in marketing and promotions of products.</p>
      <p>She takes care of our products quality also helps us in promotions and marketing.
      </p>
    </Fragment>)

    
  ];

  return (
    <Fragment>
      <div className={classes.team}>
        <Title>Our Team</Title>
        <br/>
        <div className={classes.teamMembers}>
          
          <TeamMember
            imgSrc={jaiImage}
            title="Jai Sharma"
            description={MemberDescription[0]}
          />
          <TeamMember
            imgSrc={mrinalImage}
            title="Mrinal Kukreti"
            description={MemberDescription[1]}
          />
          <TeamMember
            imgSrc={pstImage}
            title="Prashasti Kukreti"
            description={MemberDescription[2]}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default Team;
