
function Games() {
    return (
        <div>
        This is Games Page!
        </div>
      
    );
  }
  
  export default Games;