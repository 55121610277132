// Initialize Cloud Firestore through Firebase
import firebase from "firebase";
import React, { useEffect, useState } from "react";
import firebaseConfig from "../../utils/firebaseConfig";
import './Requests.scss';

// Initialize Firebase
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  } else {
    firebase.app(); // if already initialized, use that one
  }



// const ClassContext = React.createContext();
const db = firebase.firestore();

const passphrase = "anvarat202020";
class Requests extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            registers: [],
            queries: [],
            password: '',
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        db.collection("registers").get().then((querySnapshot) => {
            let docs = [];
            querySnapshot.forEach(doc => {
                let docData = doc.data();
                docs.push(docData);
            });
            this.setState({ registers: docs });
            return true;
            // console.log("internal register:" + registers);
        });

        db.collection("queries").get().then((querySnapshot) => {
            let docs = [];
            querySnapshot.forEach(doc => {
                let docData = doc.data();
                docs.push(docData);
            });
            this.setState({ queries: docs });
            return true;
            // console.log("internal register:" + registers);
        });
    }

    render(){
        if(this.state.password === passphrase)
        {
            return (<div className="requests">
                <table>
                {
                this.state.registers.length > 0? this.state.registers.map((doc,key)=> {
                    return <tr>
                    <td>{key+1}</td>
                    <td>{doc.email}</td>
                    <td>{doc.pageContext}</td></tr>;
                }):"test"}
                
                </table>

                <table>
                {
                this.state.queries.length > 0? this.state.queries.map((doc, key)=> {
                    return <tr>
                    <td>{key+1}</td>
                    <td>{doc.email}</td>
                    <td>{doc.query}</td></tr>;
                }):"test"}
                
                </table>

                </div>)
        }
        
        else 
            return(
            <div className="requests">
            <input type="password" value={this.state.password} onChange={this.handleChange} />
            </div>)
    }

    handleChange(event) {
        this.setState({password: event.target.value});
        }
}



export default Requests;