// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBx-dbL_SIOi6_iWFdU5415wNbFno1a9cs",
  authDomain: "anvarat-site.firebaseapp.com",
  databaseURL: "https://anvarat-site.firebaseio.com",
  projectId: "anvarat-site",
  storageBucket: "anvarat-site.appspot.com",
  messagingSenderId: "92873399246",
  appId: "1:92873399246:web:5f6c7714c143938554299f"
};

export default firebaseConfig;