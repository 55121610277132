import "./App.css";
import firebase from "firebase";
import firebaseConfig from "./utils/firebaseConfig";
import 'firebase/analytics';

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Games from "./games/Games";
import Home from "./Layout/Home/Home";
import About from "./Layout/About/About";
import CPNDJ from "./Layout/CPNDJ/CPNDJ";
import "./style.scss";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";

// social icons
import FB from "./assets/social/fb.png";
import IN from "./assets/social/in.png";
import DC from "./assets/social/dc.png";
import YT from "./assets/social/yt.png";
import Footer from "./components/Footer";
import MenuIcon from "@material-ui/icons/Menu";
import Vajra from "./Layout/Vajra/Vajra";
import PrivacyPolicy from './utils/privacyPolicy';
import TLP from "./Layout/TLP/TLP";
import Requests from "./Layout/Requests/Requests";

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app(); // if already initialized, use that one
}

firebase.analytics();

function App() {
  return (
    <div className="full-height">
      <Navbar fixed="top" bg="custom-dark dark" expand="lg" variant="dark">
        <Navbar.Brand href="/"></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <MenuIcon />
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <NavDropdown title="Games" id="basic-nav-dropdown">
              <NavDropdown.Item href="/games/tlp">
                The Longest Path
              </NavDropdown.Item>
              <NavDropdown.Item href="/games/vajra">Vajra</NavDropdown.Item>
              <NavDropdown.Item href="/games/cpndj">
                Crazy Pets / Double Jump
              </NavDropdown.Item>
            </NavDropdown>

            <Nav.Link href="/about">About Us</Nav.Link>
          </Nav>

          <Nav className="social">
            <Nav.Link
              href="https://www.facebook.com/AnvaratStudio"
              target="new"
            >
              <img src={FB} />
            </Nav.Link>
            <Nav.Link
              href="https://www.instagram.com/anvaratstudio/"
              target="new"
            >
              <img src={IN} />
            </Nav.Link>
            <Nav.Link href="https://discord.gg/GAguecMUaT" target="new">
              <img src={DC} />
            </Nav.Link>
            <Nav.Link
              href="https://www.youtube.com/channel/UChDB55F7PJ7VEjBMsrYeZeg"
              target="new"
            >
              <img src={YT} />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <Router>
        <Switch>
          <Route path="/anvarat-requests">
            <Requests />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/games/cpndj">
            <CPNDJ />
          </Route>
          <Route path="/games/vajra">
           <Vajra/>
          </Route>
          <Route path="/games/tlp">
           <TLP/>
          </Route>
          <Route path="/games">
            <Games />
          </Route>
          <Route path="/privacy-policy">
            <PrivacyPolicy />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>

      <Footer />
    </div>
  );
}

export default App;
