import { Fragment } from "react";
import classes from "./TeamMember.module.css";

const TeamMember = (props) => {
  return (
    <Fragment>
      <div className={classes.teamMember}>
        <div className={classes.imageContainer}>
          <img className={classes.image} src={props.imgSrc} alt={props.alt} />
        </div>
        <div className={classes.details}>
          <span className={classes.title}>{props.title}</span>
          <span className={classes.description}>{props.description}</span>
        </div>
      </div>
    </Fragment>
  );
};

export default TeamMember;
