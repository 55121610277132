import { Fragment } from "react";
import CardButton from "../../../../../UI/Buttons/CardButton/CardButton";
import classes from "./GameCard.module.css";

const GameCard = (props) => {
  return (
    <Fragment>
      <div className={classes.gameCard}>
        <img className={classes.image} src={props.src} alt={props.alt} />
        <div className={classes.infoPanel}>
          <span className={classes.title}>{props.title}</span>
          <span className={classes.description}>{props.description}</span>
        </div>
        <div className={classes.buttonHolder}>
          <a href={props.linkURL} ><CardButton className={classes.cardButton}>See More</CardButton></a>
        </div>
      </div>
    </Fragment>
  );
};

export default GameCard;
