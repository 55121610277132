import { Fragment } from "react";
import ImageTextPanel from "../../../UI/ImageTextPanel/ImageTextPanel";
import Title from "../../../UI/Title/Title";
import classes from "./StoryContainer.module.css";
import placeholderStoryImage from "../../../assets/Group91.png";

const StoryContainer = (props) => {
  return (
    <Fragment>
      <div className={classes.storyContainer}>
        <Title>Our Story</Title>
        <ImageTextPanel
          className={classes.description}
          imgSrc={placeholderStoryImage}
        >
          <p>
            We have always been very excited about video games. The potential and possibilities of what can be achieved through a well delivered game.
            Videogames are powerful tools when it comes to teaching, and storyteliing.
          </p>
          <p>
              While being used by business to earn money, the actual values of games have been lost overtime. The fun and jow are now replaced with pay to win.
              story and teachings have been lost to in-app purchases. Our vision is to stick with ethics of delivering quality and story and not to focus on pulling money out of players.
          </p>
          <p>
            Although the story of anvarat started from a FB post, but we soon realised that the vision of the founders are pretty much aligned.
            Hence for the love of games and the untold stories of India, we Anvarat strive to deliver games which tell tales of our land and helps the world learn more about the cultures of India.
          </p>
        </ImageTextPanel>
      </div>
    </Fragment>
  );
};

export default StoryContainer;
