import firebase from 'firebase';
import 'firebase/firestore';
import firebaseConfig from './firebaseConfig';
import validator from 'validator';
import React from 'react';

// Initialize Firebase
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
    }else {
    firebase.app(); // if already initialized, use that one
}

const db = firebase.firestore();
const collection = 'registers'
const d = new Date()

/* this function saves the email into database along with page context and date time of the post
 *** Input - 
 * emailVal - this is the email address to be saved to the database
 * pageContextVal - this is the page context to be saved to databae
 *** Output -
 * false - if email validation failed
 ** below 2 are delayed results since they depend on server response and temporarily provides a promis
 * false - if there was some error while saving record to the database
 * true - if value was saved successfully
 */
const mailSaver = (emailVal, pageContextVal) => {
    // compiling date
    var dateTimeVal = d.getUTCDate()+"-"+d.getUTCMonth()+"-"+d.getUTCFullYear()+" "+d.getUTCHours()+":"+d.getUTCMinutes()+":"+d.getUTCMilliseconds();
    var record = {
        email : emailVal,
        pageContext : pageContextVal,
        dateTime : dateTimeVal,
    };
    var isEmail = validator.isEmail(emailVal);

    // validate email address
    if (!isEmail)
    {
        return false;
    }
    else
    {
        return db.collection(collection).add(record).then((docRef) => {
                return true;
            })
            .catch((error) => {
                
                console.log("some error occured",error);
                return false;
            });
    }

};

export default mailSaver;
