import { Fragment } from "react";
import classnames from 'classnames';
import classes from "./Title.module.css";
import leftArrow from "../../assets/union-left.png";
import rightArrow from "../../assets/union-right.png";

const Title = (props) => {
  var allClasses = classnames(classes.titleContainer, props.className);
  var leftArrowImage = ( props.leftArrow) ? props.leftArrow : leftArrow;
  var rightArrowImage = ( props.rightArrow) ? props.rightArrow : rightArrow;
  return (
    <Fragment>
      <div className={allClasses}>
        <img className={classes.arrow} src={leftArrowImage} alt="left-arrow"></img>
        <span className={classes.titleText}>{props.children}</span>
        <img className={classes.arrow} src={rightArrowImage} alt="right-arrow"></img>
      </div>
    </Fragment>
  );
};
export default Title;
