import { Fragment, useState } from "react";
import  "./InputBox.css";
import mailSaver from "../../utils/mailSaver";
import { CircularProgress } from "@material-ui/core";
import Snackbar from '@material-ui/core/Snackbar';

const InputBox = (props) => {

  // required props - pageContext - this sets the source of this email registration
  // const pageContext = props.pageContext ? props.pageContext : "pageContext Missing";
  const pageContext = window.location.href;

  //hooks for states
  const [email, setemail] = useState("");
  const [serverResponse, setServeResponse] = useState("none");
  const [snackbar, setSnackbar] = useState(false);
  
  const onEmailChange = (e) => {
      setemail(e.target.value);
  }

  const submitMail = () => {
    var response = mailSaver(email, pageContext);
    if (typeof response.then === "function") {
      setServeResponse ("loading");
      response.then((res) => {
        changeStates (true);
      })
      .catch((error) => {
        changeStates (false);
      });
    }
    else {
      setServeResponse ("none");
      changeStates(response);
    }
  }

  const changeStates = (response) => {
    if (response === true) {
      setServeResponse ("success");
      setSnackbar(true);
      setemail('')
    }
    else if (response === false) {
      setServeResponse ("failure");
      setSnackbar(true);
    }
    else {
      setServeResponse ("none");
    }
  }

  const sncakbarClose = () => {
    setSnackbar(false);
  }

  const circularProgress = <CircularProgress className="circular-progress" size="24" />;

  var emailPlaceHolder = (props.emailPlaceHolder) ? props.emailPlaceHolder : "Email Address";

  return (
    <Fragment>
    <Snackbar
      open={snackbar}
      message={serverResponse === "success"?"Thanks for registering :)":"Some server error occured!"}
      anchorOrigin = {{horizontal: 'center', vertical: 'bottom'}}
      autoHideDuration={6000}
      onClose={sncakbarClose}
    />
      <div className='inputBox'>
          <input type='email' placeholder={emailPlaceHolder} value={email} onChange={onEmailChange}></input>
          <button onClick={submitMail} type='submit'>{serverResponse === "loading" ? circularProgress : props.children}</button>
      </div>
    </Fragment>
  );
};

export default InputBox;
