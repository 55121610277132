import { Fragment, Component } from "react";
import ImageTextPanel from "../../UI/ImageTextPanel/ImageTextPanel";
import IntroContainer from "../../UI/Intro/IntroContainer";
import StayConnectedPanel from "../../UI/StayConnectedPanel/StayConnectedPanel";
import Title from "../../UI/Title/Title";
import ContentHolder from "../About/ContentHolder/ContentHolder";
import crazyPetsImage from "../../assets/crazyPets_DP.png";
import doubleJumpImage from "../../assets/doubleJump_DP.png";
import leftArrowImage from "../../assets/left_arrow_green.png";
import rightArrowImage from "../../assets/right_arrow_green.png";
import "./CPNDJ.css";
import DocumentMeta from 'react-document-meta';

class CPNDJ extends Component{
  componentDidMount() {
    document.title = "Crazy Pets & Double Jump";
  }

  crazyPetsDescription = (
    <Fragment>
    <DocumentMeta description="Crazy pets and Double jump are our android games, created for hypercasual gamers. Carzy pets is an infinite runner while double jump is an infinite survival by juming and avoiding projectiles" />
    <p>When candy thief thinks he can escape with no one watching, Your agile pets are ready for challenge. They are up for chase, not just any chase but crazy chase to catch that candy thief and bring back what's rightfully yours.</p>
      <p>
      Help your pets avoid obstacles and collect awesome pickups in order to catch the thief and bring back your candy.
</p>
<p>
Every pet has its own skill, choose your pet wisely, because once they are out, there is no turning back. The chase is not easy, but neither are your pets. Stay focused, stay agile, keep those fingers tapping, your pets need you as much as you need them.
      </p>
    </Fragment>
  );

  doubleJumpDescription = (
    <Fragment>
      <p>
      Get hold of THE most addictive game that you’ll ever come across. Here is an insane fast paced jumping game - Double Jump.</p>

      <p>Jump. Dodge. And save yourself from the obstacles coming your way. Very Simple. Super Fun.</p>

<p>How to play? Well, Tap to jump and double tap to jump higher. That’s all!</p>

<p>Play the game, challenge your friends. See their scores. Beat them. Why limit to friends when you can challenge yourself and get better at it everyday.
      </p>
    </Fragment>
  );

  render() {
    return (
      <Fragment>
        <div class="cpndj">
          <IntroContainer title="" subTitle="" />
          <ContentHolder>
            <div className="container">
              <Title leftArrow={leftArrowImage} rightArrow={rightArrowImage}>
                Crazy Pets
              </Title>
              <ImageTextPanel
                showButton={true}
                className="imageTextPanel"
                imgSrc={crazyPetsImage}
                onClickButton={(e) => {
                  e.preventDefault();
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.Anvarat.CrazyPets", 'new');
                }}
              >
                {this.crazyPetsDescription}
              </ImageTextPanel>
              <Title
                leftArrow={leftArrowImage}
                rightArrow={rightArrowImage}
                className="margin20"
              >
                Double Jump
              </Title>
              <ImageTextPanel
                showButton={true}
                className="imageTextPanel"
                imgSrc={doubleJumpImage}
                onClickButton={(e) => {
                  e.preventDefault();
                 window.open(
                    "https://play.google.com/store/apps/details?id=com.Anvarat.Hoppian", 'new');
                }}
              >
                {this.doubleJumpDescription}
              </ImageTextPanel>
            </div>
            <StayConnectedPanel
              leftArrow={leftArrowImage}
              rightArrow={rightArrowImage}
              title="ENROLL FOR UPDATES"
              description="Become a beta tester, test our games before anyone else and share your feedback"
            />
          </ContentHolder>
        </div>
      </Fragment>
    );
  }

}

export default CPNDJ;
