import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import CardButton from "../Buttons/CardButton/CardButton";
import validator from 'validator';
import './contactus.scss';

// firebase
import firebase from 'firebase';
import 'firebase/firestore';
import firebaseConfig from '../../utils/firebaseConfig';

// Initialize Firebase
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
    }else {
    firebase.app(); // if already initialized, use that one
}

const db = firebase.firestore();
const collection = 'queries'
const d = new Date()

const ContactUs = (props) => {
    const [emailVal, setemail] = useState('');
    const [queryVal, setQuery] = useState('');
    const [emailValid, setEmailValid] = useState(true);
    const [queryValid, setQueryValid] = useState(true);

    const onEmailChange = (e) =>
    setemail(e.target.value);

    const onQueryChange = (e) =>
    setQuery(e.target.value);

    const submitQuery = () => {
        //get values and post them in firebase
        // check if email is valid
        var allVaid = true
        if(!validator.isEmail(emailVal))
        {
            // do nothing
            setEmailValid(false);
            allVaid = false;
        }
        else {
            setEmailValid(true);
        }
        if(queryVal.length < 3)
        {
            // do nothing
            setQueryValid(false);
            allVaid = false;
        }
        else {
            setQueryValid(true);
        }
        if (allVaid)
        {
            var dateTimeVal = d.getUTCDate()+"-"+d.getUTCMonth()+"-"+d.getUTCFullYear()+" "+d.getUTCHours()+":"+d.getUTCMinutes()+":"+d.getUTCMilliseconds();
            var record = {
                email : emailVal,
                query : queryVal,
                dateTime : dateTimeVal,
            };
            db.collection(collection).add(record)
            // now you can submit all the values
            props.handleClose();
        }
    }

    return (
      <div className="contact-us-modal">
        <Modal show={props.showContactUs} onHide={props.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title style={{color:"#333"}}>Contact Us</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Control type="email" onChange={onEmailChange} placeholder="name@example.com" isInvalid={!emailValid} />
            <br></br>
            <Form.Control as="textarea" onChange={onQueryChange} rows={3} placeholder="your query" isInvalid={!queryValid} />
          </Modal.Body>
          <Modal.Footer>
            <CardButton onClick={submitQuery}>
              SUBMIT
            </CardButton>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

export default ContactUs