import { Fragment, Component } from "react";
import IntroContainer from "../../UI/Intro/IntroContainer";
import Title from "../../UI/Title/Title";
import ContentHolder from "../About/ContentHolder/ContentHolder";
import "./TLP.css";
import leftArrowImage from "../../assets/left_arrow_blue.png";
import rightArrowImage from "../../assets/right_arrow_blue.png";
import StayConnectedPanel from "../../UI/StayConnectedPanel/StayConnectedPanel";
import DocumentMeta from "react-document-meta";
import InputBox from "../../UI/InputBox/InputBox";
import ReactPlayer from "react-player";
import signUpImage from "../../assets/sign_up.png";
import signUpImageSmall from "../../assets/SignUpImageSmall.png";

class TLP extends Component {
  componentDidMount() {
    document.title = "The Longest Path Game";
  }
  render() {
    return (
      <Fragment>
        <DocumentMeta description="The Longest Path Game - It is a 2D metroidvania game with touch of action and story, set in ancient india, it is a story of loss pain and anger" />
        <div className="tlp">
          <IntroContainer>
            <div className="content">
              <div className="registerContainer">
                <h1 className="registerTitle">The Longest Path</h1>
                <span className="registerDescription">
                  <p>
                    Loss, grief and anger makes an army commander set forth on a
                    journey to find the purpose of his life.
                  </p>

                  <p>
                    As he travels across different realms to find enlightenment,
                    he finds it unavoidable to draw his swords and forge his
                    path forward.
                  </p>

                  <p>
                    In every realm he will find different foes, and puzzles
                    which he must face to proceed. Join him in his journey and
                    find out what he gets in the end.
                  </p>
                </span>

                <span className="registerInput">
                  <InputBox classNam>REGISTER NOW</InputBox>
                  <h3>No Spams, major updates only</h3>
                  <img className="signUpImage" src={signUpImage} alt="sign up image"/>
                  <img className="signUpImageSmall" src={signUpImageSmall} alt="sign up image"/>
                </span>
              </div>

              <div className="teaserContainer">
                <Title leftArrow={leftArrowImage} rightArrow={rightArrowImage}>
                  Watch Teaser
                </Title>
                <div className="teaserPlayer">
                  <ReactPlayer
                    width='100%'
                    height='100%'
                    url="https://youtu.be/LO50yCaSVDo"
                  ></ReactPlayer>
                </div>
              </div>
            </div>
          </IntroContainer>
        </div>
      </Fragment>
    );
  }
}

export default TLP;
