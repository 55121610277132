import { Fragment } from "react";
import "./StayConnectedPanel.css";
import Title from "../Title/Title";
import InputBox from "../InputBox/InputBox";

const StayConnectedPanel = (props) => {
  return (
    <Fragment>
      <div class="stayConnectedPanel">
        <div class="container">
          <Title leftArrow={props.leftArrow} rightArrow={props.rightArrow}>
            {props.title}
          </Title>
          <div className="detailsPanel">
            <div className="subText">
              <span>{props.description}</span>
            </div>
            <div className="emailPanel">
              <div className="emailBox">
                <InputBox>Join</InputBox>
              </div>
              <span>No Spams, major updates only</span>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default StayConnectedPanel;
